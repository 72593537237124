<template>
  <div class="mt-6 md:mt-8 ds-wrapper legals-custom">
    <div class="custom-quillEditor quill-editor">
      <h4 class="font-medium text-xl md:text-3xl text-dark-blue mb-4 md:mb-6">{{ currentLegal ? currentLegal.documentName : "" }}</h4>
      <vx-card class="terms-card">
        <div ref="myQuillEditor" class="terms-view-content ql-editor" v-html="currentLegal.explicitTerms" v-if="currentLegal.explicitTerms"/>
        <vs-divider class="terms-divider" v-if="currentLegal.explicitTerms"></vs-divider>
        <div ref="myQuillEditor" class="terms-view-content ql-editor" v-if="currentLegal.fullDocument" v-html="currentLegal.fullDocument"/>
      </vx-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "PrivacyPolicy",
  components: {
    quillEditor,
  },

  data() {
    return {
      currentLegal: "",
      type: "",
      fullDocument: true,
      appName: process.env.VUE_APP_NAME,
      options: {
        debug: "info",
        theme: "bubble",
      },
      mode: ""
    };
  },

  methods: {
    ...mapActions("legals",["fetchLegalWithId", "fetchProductLegalsById"]),
    getTerms(id,type) {
      this.$vs.loading();
      const params = { type, id };
      this.fetchLegalWithId(params).then((result) => {
        this.$vs.loading.close();
        const legals = result.data.data.legals;
        const latestPrivacy = result.data.data.privacy;
        const latestWebsiteTerms = result.data.data.websiteTerms;
        this.currentLegal = legals.find((el) => el._id === id);
        const partner = JSON.parse(localStorage.getItem("userInfo"));
        let defaultDescriptor = (partner && partner.merchantDescriptor) ? partner.merchantDescriptor : "";

        if (this.mode && !this.mode.includes("now")) {
          defaultDescriptor = this.appName.toLowerCase().includes("flip") ? "FlipPay.com.au" : "rello.com.au";
        }

        // if fulldocument arg is passed, populate the fulldocument and parse any links
        if (this.fullDocument) {
          this.currentLegal.fullDocument = this.replaceLegalContent(
            this.currentLegal.fullDocument,
            {
              fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
              privacy: `<a href="${latestPrivacy._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestPrivacy.documentName}</a>`,
              generalWebsiteTerms: `<a href="${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestWebsiteTerms.documentName}</a>`,
              merchantDescriptor: defaultDescriptor,
            }
          );
        }

        if (this.currentLegal.explicitTerms) {
          this.currentLegal.explicitTerms = this.replaceLegalContent(
            this.currentLegal.explicitTerms,
            {
              fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
              privacy: `<a href="${latestPrivacy._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestPrivacy.documentName}</a>`,
              generalWebsiteTerms: `<a href="${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestWebsiteTerms.documentName}</a>`,
              merchantDescriptor: defaultDescriptor,
            }
          );
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
    getProductTerms(id) {
      this.$vs.loading();
      this.fetchProductLegalsById({ id: id, customer: "" }).then((result) => {
        this.$vs.loading.close();
        const {legals} = result.data.data.legals;
        const latestPrivacy = result.data.data.privacy;
        const latestWebsiteTerms = result.data.data.websiteTerms;
        this.currentLegal = legals.find((el) => el._id === id);

        // if fulldocument arg is passed, populate the fulldocument and parse any links
        if (this.fullDocument) {
          this.currentLegal.fullDocument = this.replaceLegalContent(
              this.currentLegal.fullDocument,
              {
                fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
                privacy: `<a href="${latestPrivacy._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestWebsiteTerms.documentName}</a>`,
              }
          );
        }

        if (this.currentLegal.explicitTerms) {
          this.currentLegal.explicitTerms = this.replaceLegalContent(
              this.currentLegal.explicitTerms,
              {
                fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
                  privacy: `<a href="${latestPrivacy._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=false" target="_blank">${latestWebsiteTerms.documentName}</a>`,
              }
          );
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
  },

  async beforeMount() {
    this.type = this.$route.query.type;
    this.mode = this.$route.query.mode;
    const id = this.$route.params.id;

    if (this.type !== "PAY_LATER_LEGAL") {
      await this.getTerms(id, this.type);
    } else {
      await this.getProductTerms(id);
    }
  },
};
</script>

